import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

import Button from '@mui/material/Button';
import { Snackbar } from '@mui/material';

import '../App.css';

function PartnersPage() {

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [showMore, setShowMore] = useState(false);

    const handleShowMore = () => {
        setShowMore(true);
    };

    const handleShowLess = () => {
        setShowMore(false);
    };

    const renderCoins = () => {
        if (showMore) {
            return (
                <div className='MainPage-menu'>
                    <div className="MainPage-menu-item" data-link="https://nft.stablemetal.com">
                        $STBL
                    </div>
                    <div className="MainPage-menu-item" data-link="https://Tonlancer.com">
                        $LCR
                    </div>
                    <div className="MainPage-menu-item full" data-link="https://t.me/Tribeton_bot">
                        $TRIBE
                    </div>
                </div>
            );
        } else {
            return (
                <div className='MainPage-menu'>
                    <div className="MainPage-menu-item" data-link="http://t.me/architecton_tech">
                        $BNK
                    </div>
                    <div className="MainPage-menu-item" data-link="https://t.me/beetontoken/229">
                        $BEE
                    </div>
                    <div className="MainPage-menu-item" data-link="https://t.me/pdfminercoin">
                        $PDFB
                    </div>
                    <div className="MainPage-menu-item" data-link="https://Unic.pro">
                        $UNIC
                    </div>
                </div>
            );
        }
    };

    const redirectToPage = (event) => {

        HapticFeedback.notificationOccurred('success');

        const link = event.target.getAttribute('data-link');
        if (link) {
            window.location.href = link;
        }
    };

    const { HapticFeedback } = window.Telegram.WebApp;
    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/'
        BackButton.hide();
    });

    useEffect(() => {
        const pageElement = document.querySelector('.Page');
        if (pageElement) {
            pageElement.style.opacity = 1;

            setTimeout(() => {
                pageElement.style.animation = 'none';
            }, 500);
        }
    }, []);

    return (
        <div className='Page'>
            <Header title='ПАРТНЁРЫ' className='Header' />
            <div className="MainPage-menu" onClick={redirectToPage}>
                {renderCoins()}
                <div className="MainPage-menu-item full" onClick={showMore ? handleShowLess : handleShowMore}>
                    {showMore ? 'НАЗАД' : 'ДАЛЕЕ'}
                </div>
            </div>
        </div>
    );
}

export default PartnersPage;