import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

import '../App.css';
import cross from '../Images/cross-svgrepo-com (1).svg';
import mapImage from '../Images/photo_2024-03-17_13-38-59.jpg';

function InfoPage() {
    const [showOverlay, setShowOverlay] = useState(false);
    const [overlayContent, setOverlayContent] = useState({});

    const redirectToPage = (event) => {
        const link = event.target.getAttribute('data-link');
        if (link) {
            if (link === "/about") {
                setShowOverlay(true);
                setOverlayContent({
                    text: 'Lucky TON — уникальный токен, воплощающий идею счастья и удачи для его владельцев. Считается, что обладание этим знаком привлекает процветание и удачу. Обладая положительной энергетикой, он символизирует возможность пережить счастливые события в жизни своих владельцев. Помимо своей финансовой ценности, Lucky TON служит символом оптимизма и радости, оказывая положительное влияние на держателей токенов. Lucky TON можно использовать для покупки 8 уникальных NFT под названием «Lucky TON NFT #1-8», которые обещают приносить доход. Эти NFT предоставляют владельцам Lucky TON дополнительные возможности участвовать в мероприятиях, приносящих доход, или получать другие преимущества. Это создает экосистему, в которой владение как токенами, так и NFT может предложить владельцам уникальные возможности и прибыльные перспективы.',
                    image: null
                });
            } else if (link === "/map") {
                setShowOverlay(true);
                setOverlayContent({
                    text: '',
                    image: mapImage
                });
            } else {
                window.location.href = link;
            }
        }
    };

    useEffect(() => {
        const pageElement = document.querySelector('.Page');
        if (pageElement) {
            pageElement.style.opacity = 1;
            setTimeout(() => {
                pageElement.style.animation = 'none';
            }, 500);
        }
    }, []);

    const closeOverlay = () => {
        setShowOverlay(false);
    };

    const { HapticFeedback } = window.Telegram.WebApp;
    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/'
        BackButton.hide();
    });

    return (
        <div className='Page'>
            <Header title='ИНФОРМАЦИЯ' className='Header' />
            <div className="MainPage-menu" onClick={redirectToPage}>
                <div className="MainPage-menu-item" data-link="/about">
                    ПРО $LKY
                </div>
                <div className="MainPage-menu-item" data-link="https://t.me/lkyappbot/chart">
                    ГРАФИК
                </div>
                <div className="MainPage-menu-item full" data-link="/map">
                    ДОРОЖНАЯ КАРТА
                </div>
                <div className="MainPage-menu-item full" data-link="https://t.me/InstantFormsBot/form?startapp=9bbd91c6-50da-4f7c-9aac-865efc7621b1&startApp=9bbd91c6-50da-4f7c-9aac-865efc7621b1">
                    ПРЕДЛОЖИТЬ ИДЕЮ
                </div>
            </div>
            {showOverlay && (
                <div className="overlay">
                    <div className="overlay-content">
                        <span className="close-btn" onClick={() => setShowOverlay(false)}>
                            <img src={cross} alt="" />
                        </span>
                        <p>{overlayContent.text}</p>
                        {overlayContent.image && <img src={overlayContent.image} className='map' alt="Map" />}
                    </div>
                </div>
            )}
        </div>
    );
}

export default InfoPage;