import React, { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import music from './Images/music.mp3';
import '@telegram-apps/telegram-ui/dist/styles.css';

function App() {
  const audioRef = useRef(null);

  const playMusic = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <div className="App">
      {/* <button onClick={playMusic}>Play Music</button>
      <audio ref={audioRef} src={music} /> */}
      <Outlet />
    </div>
  );
}

export default App;