import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

import '../App.css';

function ContactPage() {

    const redirectToPage = (event) => {

        HapticFeedback.notificationOccurred('success');

        const link = event.target.getAttribute('data-link');
        if (link) {
            window.location.href = link;
        }
    };

    const { HapticFeedback } = window.Telegram.WebApp;
    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/social'
        BackButton.hide();
    });

    useEffect(() => {
        const pageElement = document.querySelector('.Page');
        if (pageElement) {
            pageElement.style.opacity = 1;

            setTimeout(() => {
                pageElement.style.animation = 'none';
            }, 500);
        }
    }, []);

    return (
        <div className='Page'>
            <Header title='КОНТАКТЫ' className='Header' />
            <div className="MainPage-menu" onClick={redirectToPage}>
                <div className="MainPage-menu-item full" data-link="https://t.me/lkyton">
                    TELEGRAM
                </div>
            </div>
        </div>
    );
}

export default ContactPage;