import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';


import '../App.css';

function ReferralPage() {

    const userName = window.Telegram.WebApp.initDataUnsafe.user.first_name;

    const redirectToPage = (event) => {

        HapticFeedback.notificationOccurred('success');

        const link = event.target.getAttribute('data-link');
        if (link) {
            window.location.href = link;
        }
    };

    console.log(userName.toUpperCase());

    const { HapticFeedback } = window.Telegram.WebApp;
    const { WebAppUser } = window.Telegram.WebApp;
    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/'
        BackButton.hide();
    });

    useEffect(() => {
        const pageElement = document.querySelector('.Page');
        if (pageElement) {
            pageElement.style.opacity = 1;

            setTimeout(() => {
                pageElement.style.animation = 'none';
            }, 500);
        }
    }, []);

    return (
        <div className='Page'>
            <Header title='РЕФЕРАЛКА' className='Header' />
            <div className="MainPage-menu" onClick={redirectToPage}>
                <div className="MainPage-menu-item full">
                    {userName.toUpperCase()}
                </div>
                <div className="MainPage-menu-item">
                    БАЛАНС: 0 $LKY
                </div>
                <div className="MainPage-menu-item">
                    ВЫВОД
                </div>
                <div className="MainPage-menu-item full">
                    СКОПИРОВАТЬ РЕФ. ССЫЛКУ
                </div>
            </div>
        </div>
    );
}

export default ReferralPage;