import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

import '../App.css';

function NavigationPage() {

    const [showMore, setShowMore] = useState(false);

    const handleShowMore = () => {
        setShowMore(true);
    };

    const handleShowLess = () => {
        setShowMore(false);
    };

    const renderCoins = () => {
        if (showMore) {
            return (
                <div className='MainPage-menu'>
                    <div className="MainPage-menu-item full" data-link="http://instagram.com/lky_ton">
                        INSTAGRAM
                    </div>
                    <div className="MainPage-menu-item full" data-link="http://youtube.com/@lkyton">
                        YOUTUBE CHANNEL
                    </div>
                </div>
            );
        } else {
            return (
                <div className='MainPage-menu'>
                    <div className="MainPage-menu-item" data-link="http://luckyton.org/">
                        ВЕБ-САЙТ
                    </div>
                    <div className="MainPage-menu-item" data-link="http://t.me/lkychat">
                        ЧАТ
                    </div>
                    <div className="MainPage-menu-item" data-link="http://x.com/lkyton">
                        TWITTER / X
                    </div>
                    <div className="MainPage-menu-item" data-link="http://tiktok.com/@lkyton">
                        TIKTOK
                    </div>
                </div>
            );
        }
    };

    const redirectToPage = (event) => {

        HapticFeedback.notificationOccurred('success');

        const link = event.target.getAttribute('data-link');
        if (link) {
            window.location.href = link;
        }
    };

    const { HapticFeedback } = window.Telegram.WebApp;
    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/social'
        BackButton.hide();
    });

    useEffect(() => {
        const pageElement = document.querySelector('.Page');
        if (pageElement) {
            pageElement.style.opacity = 1;

            setTimeout(() => {
                pageElement.style.animation = 'none';
            }, 500);
        }
    }, []);

    return (
        <div className='Page'>
            <Header title='НАВИГАЦИОННЫЕ ССЫЛКИ' className='Header' />
            <div className="MainPage-menu" onClick={redirectToPage}>
                {renderCoins()}
                <div className="MainPage-menu-item full" onClick={showMore ? handleShowLess : handleShowMore}>
                    {showMore ? 'НАЗАД' : 'ДАЛЕЕ'}
                </div>
            </div>
        </div>
    );
}

export default NavigationPage;