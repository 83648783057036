import React, { useEffect } from 'react';
import logo from '../Images/Logo white.png';

function Header({ title }) {

    useEffect(() => {
        const image = document.querySelector('.image img');
        image.classList.add('rotate');
    }, []);

    return (
        <div className='Header'>
            <div className="title">
                {title}
            </div>
            <div className="image">
                <img src={logo} alt="" />
            </div>
        </div>
    );
}

export default Header;