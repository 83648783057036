import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

import '../App.css';

function MainPage() {

    const { HapticFeedback } = window.Telegram.WebApp;

    const redirectToPage = (event) => {

        HapticFeedback.notificationOccurred('success');

        const link = event.target.getAttribute('data-link');
        if (link) {
            window.location.href = link;
        }
    };

    return (
        <div className='Page'>
            <Header title='ГЛАВНОЕ МЕНЮ' className='Header' />
            <div className="MainPage-menu" onClick={redirectToPage}>
                <div className="MainPage-menu-item" data-link="/info">
                    ИНФОРМАЦИЯ
                </div>
                <div className="MainPage-menu-item" data-link="/products">
                    ПРОДУКТЫ
                </div>
                <div className="MainPage-menu-item" data-link="/ecosystem">
                    ЭКОСИСТЕМА
                </div>
                <div className="MainPage-menu-item" data-link="/buy">
                    КУПИТЬ
                </div>
                <div className="MainPage-menu-item full" data-link="/partners">
                    ПАРТНЁРЫ
                </div>
                {/* <div className="MainPage-menu-item" data-link="/referral">
                    РЕФЕРАЛКА
                </div> */}
            </div>
        </div>
    );
}

export default MainPage;