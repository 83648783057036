import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

import '../App.css';

function GroupsPage() {

    const redirectToPage = (event) => {

        HapticFeedback.notificationOccurred('success');

        const link = event.target.getAttribute('data-link');
        if (link) {
            window.location.href = link;
        }
    };

    const { HapticFeedback } = window.Telegram.WebApp;
    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/boost'
        BackButton.hide();
    });

    useEffect(() => {
        const pageElement = document.querySelector('.Page');
        if (pageElement) {
            pageElement.style.opacity = 1;

            setTimeout(() => {
                pageElement.style.animation = 'none';
            }, 500);
        }
    }, []);

    return (
        <div className='Page'>
            <Header title='БУСТ ОСНОВНЫХ ГРУПП' className='Header' />
            <div className="MainPage-menu" onClick={redirectToPage}>
                <div className="MainPage-menu-item" data-link="https://t.me/lkyspace">
                    LUCKY TON SPACE
                </div>
                <div className="MainPage-menu-item" data-link="https://t.me/lkychat">
                    LUCKY CHAT
                </div>
                <div className="MainPage-menu-item" data-link="https://t.me/lkyvip">
                    LUCKY VIP
                </div>
                <div className="MainPage-menu-item" data-link="https://t.me/lkyboost">
                    LUCKY BOOST
                </div>
                <div className="MainPage-menu-item" data-link="https://t.me/lkyceo">
                    LUCKY TEAM
                </div>
                <div className="MainPage-menu-item" data-link="https://t.me/lkyceo">
                    LUCKY C-LEVEL
                </div>
            </div>
        </div>
    );
}

export default GroupsPage;