import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import { AppRoot } from '@telegram-apps/telegram-ui';

// pages

import BuyPage from './Pages/BuyPage';
import MainPage from './Pages/MainPage';
import Ecosystem from './Pages/EcosystemPage';
import ProductsPage from './Pages/ProductsPage';
import InfoPage from './Pages/InfoPage';
import PartnersPage from './Pages/PartnersPage';
import ReferralPage from './Pages/ReferralPage';
import SocialPage from './Pages/SocialPage';
import NavigationPage from './Pages/NavigationPage';
import AmbassadorPage from './Pages/AmbassadorPage';
import ContactPage from './Pages/ContactPage';
import BoostPage from './Pages/BoostPage';
import ChannelsPage from './Pages/ChannelsPage';
import GroupsPage from './Pages/GroupsPage';

const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children: [
      {
        path: '/',
        element: <MainPage />
      },
      {
        path: '/buy',
        element: <BuyPage />
      },
      {
        path: '/ecosystem',
        element: <Ecosystem />
      },
      {
        path: '/products',
        element: <ProductsPage />
      },
      {
        path: '/info',
        element: <InfoPage />
      },
      {
        path: '/partners',
        element: <PartnersPage />
      },
      {
        path: '/referral',
        element: <ReferralPage />
      },
      {
        path: '/social',
        element: <SocialPage />
      },
      {
        path: '/navigation',
        element: <NavigationPage />
      },
      {
        path: '/ambassador',
        element: <AmbassadorPage />
      },
      {
        path: '/contact',
        element: <ContactPage />
      },
      {
        path: '/boost',
        element: <BoostPage />
      },
      {
        path: '/boostchannels',
        element: <ChannelsPage />
      },
      {
        path: '/boostgroups',
        element: <GroupsPage />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppRoot>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </AppRoot>
);