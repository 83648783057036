import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

import '../App.css';

function AmbassadorPage() {

    const [showMore, setShowMore] = useState(false);

    const handleShowMore = () => {
        setShowMore(true);
    };

    const handleShowLess = () => {
        setShowMore(false);
    };

    const renderCoins = () => {
        if (showMore) {
            return (
                <div className='MainPage-menu'>
                    <div className="MainPage-menu-item" data-link="https://t.me/catonple">
                        CATON P2E
                    </div>
                    <div className="MainPage-menu-item" data-link="https://t.me/bluetidep">
                        LUCKY BLUE TIDE
                    </div>
                    <div className="MainPage-menu-item" data-link="http://t.me/tonster_ton">
                        БЛОГ ТОНСТЕРА
                    </div>
                    <div className="MainPage-menu-item" data-link="http://t.me/tonster_drops">
                        TONSTER ЧЕКИ
                    </div>
                    <div className="MainPage-menu-item" data-link="http://t.me/tonster_memes">
                        МЕМЫ ТОНСТЕРА
                    </div>
                    <div className="MainPage-menu-item" data-link="http://t.me/tonster_memes">
                        МЕМЫ ТОНСТЕРА
                    </div>
                </div>
            );
        } else {
            return (
                <div className='MainPage-menu'>
                    <div className="MainPage-menu-item" data-link="http://t.me/lifeinton">
                        LIFE IN TON
                    </div>
                    <div className="MainPage-menu-item" data-link="http://t.me/tg_majorka">
                        ИНВЕСТИЦИИ ГРИГОРИЯ
                    </div>
                    <div className="MainPage-menu-item" data-link="https://t.me/bird_lapwing_ton">
                        TON ВЫШЕ ПТИЧЬЕГО ПОЛЁТА
                    </div>
                    <div className="MainPage-menu-item" data-link="https://t.me/Pa4kaTon">
                        LUCKY ПАЧКА TON
                    </div>
                    <div className="MainPage-menu-item" data-link="https://t.me/G1n_TONik">
                        LUCKY ДЖИНTON
                    </div>
                    <div className="MainPage-menu-item" data-link="https://t.me/MH_Friends_Zone">
                        TON RABBITS
                    </div>
                </div>
            );
        }
    };

    const redirectToPage = (event) => {

        HapticFeedback.notificationOccurred('success');

        const link = event.target.getAttribute('data-link');
        if (link) {
            window.location.href = link;
        }
    };

    const { HapticFeedback } = window.Telegram.WebApp;
    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        HapticFeedback.impactOccurred('rigid');
        window.location = '/social'
        BackButton.hide();
    });

    useEffect(() => {
        const pageElement = document.querySelector('.Page');
        if (pageElement) {
            pageElement.style.opacity = 1;

            setTimeout(() => {
                pageElement.style.animation = 'none';
            }, 500);
        }
    }, []);

    return (
        <div className='Page'>
            <Header title='КАНАЛЫ АМБАССАДОРОВ' className='Header' />
            <div className="MainPage-menu" onClick={redirectToPage}>
                {renderCoins()}
                <div className="MainPage-menu-item full" onClick={showMore ? handleShowLess : handleShowMore}>
                    {showMore ? 'НАЗАД' : 'ДАЛЕЕ'}
                </div>
            </div>
        </div>
    );
}

export default AmbassadorPage;